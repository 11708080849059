import { OpExchangeItem } from '@/models/OpExchangeItem';
import { OpExchangeItemResponse } from '@/models/cms';

export class OpExchangeItemList {
  static valueOf(
    responses: OpExchangeItemResponse[],
    totalCount: number,
    isSP: boolean,
    supportsWebP: boolean
  ) {
    return new OpExchangeItemList(
      responses.map(r => OpExchangeItem.valueOf(r, isSP, supportsWebP)),
      totalCount
    );
  }

  private constructor(
    readonly items: OpExchangeItem[],
    readonly totalCount: number
  ) {}
}
