







































import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class OpExchangeFooter extends Vue {
  @Prop()
  readonly isExTop?: boolean;
}
