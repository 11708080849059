import { IOpExchangeItemCmsRepository } from '@/repositories/interface/IOpExchangeItemCmsRepository';
import { OpExchangeItem } from '@/models/OpExchangeItem';
import { OpExchangeItemList } from '@/models/OpExchangeItemList';
import { OpExchangeItemResponse } from '@/models/cms';
import { fetchListContents, selectContent } from '@/common/cms';

export class OpExchangeItemCmsRepository
  implements IOpExchangeItemCmsRepository {
  private readonly PATH = 'op-exchange-items';

  public constructor(
    private readonly isSP: boolean,
    private readonly supportsWebP: boolean
  ) {}

  /**
   * microCMS から単一のポイント交換商品を取得する。
   * 情報の取得に失敗した場合、Promise<null> を返す。
   * @param {string} id - ポイント交換商品のコンテンツ ID
   * @returns {Promise<OpExchangeItem | null>}
   */
  public async getItem(id: string): Promise<OpExchangeItem | null> {
    try {
      const resp = await selectContent<OpExchangeItemResponse>(this.PATH, id);
      return OpExchangeItem.valueOf(resp.data, this.isSP, this.supportsWebP);
    } catch (_) {
      return null;
    }
  }

  /**
   * microCMS から複数のポイント交換商品を取得する。
   * 情報の取得に失敗した場合、空の Promise<OpExchangeItemResponse> を返す。
   * @param {number} limit - 最大取得件数
   * @param {number} offset - 取得オフセット
   * @param {string} orders - 順序指定パラメタ
   * @param {string=} filters - フィルタパラメタ
   * @param {string=} q - 検索文字列
   * @returns {Promise<OpExchangeItemList>}
   */
  public async getItems(
    limit: number,
    offset: number,
    orders: string,
    filters?: string,
    q?: string
  ): Promise<OpExchangeItemList> {
    try {
      const resp = await fetchListContents<OpExchangeItemResponse>(this.PATH, {
        limit,
        offset,
        orders,
        filters,
        q
      });
      return OpExchangeItemList.valueOf(
        resp.data.contents,
        resp.data.totalCount,
        this.isSP,
        this.supportsWebP
      );
    } catch (_) {
      return OpExchangeItemList.valueOf([], 0, this.isSP, this.supportsWebP);
    }
  }
}
