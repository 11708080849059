
























































































































































































import OpExchangeFooter from '@/components/OdakyuPoint/OpExchange/OpExchangeFooter.vue';
import { Component, Vue, Watch } from 'vue-property-decorator';
import { OpExchangeItem } from '@/models/OpExchangeItem';
import { OpExchangeItemCmsRepository } from '@/repositories/OpExchangeItemCmsRepository';

@Component({ components: { OpExchangeFooter } })
export default class P0246 extends Vue {
  readonly repo = new OpExchangeItemCmsRepository(
    this.$store.state.isSP,
    this.$store.state.supportsWebP
  );

  readonly ITEMS_PER_PAGE = 24; // 1 ページあたりの表示件数
  readonly ORDER_PUBLISHED_AT_DESC = '-publishedAt'; // 新着順
  readonly ORDER_POINT_AMOUNT_ASC = 'pointAmount'; // ポイント数・昇順
  readonly ORDER_POINT_AMOUNT_DESC = '-pointAmount'; // ポイント数・降順
  readonly CATEGORIES = [
    'おすすめ',
    '宿泊',
    'リラクゼーション',
    'グルメ',
    '小田急グッズ',
    'その他'
  ];

  pageNo = 1;

  /* microCMS より取得される値 */
  items: OpExchangeItem[] = [];
  totalCount = 0;

  /* microCMS 検索パラメータ */
  categories: string[] = [];
  orders = this.ORDER_PUBLISHED_AT_DESC;
  filters = '';
  q = '';

  created() {
    this.getItems();
  }

  async getItems() {
    const resp = await this.repo.getItems(
      this.ITEMS_PER_PAGE,
      this.ITEMS_PER_PAGE * (this.pageNo - 1),
      this.orders,
      this.categories.map(c => `categories[contains]${c}`).join('[and]'),
      this.q
    );
    this.items = resp.items;
    this.totalCount = resp.totalCount;
  }

  @Watch('categories')
  @Watch('orders')
  async onChangeSearchCondition() {
    this.pageNo = 1;
    await this.getItems();
    window.scrollTo(0, 0);
  }

  @Watch('pageNo')
  async onChangePageNo() {
    await this.getItems();
    window.scrollTo(0, 0);
  }

  prevPage() {
    if (this.isPrevPageClickable) {
      this.pageNo--;
    }
  }

  nextPage() {
    if (this.isNextPageClickable) {
      this.pageNo++;
    }
  }

  get isPrevPageClickable(): boolean {
    return this.pageNo > 1;
  }

  get isNextPageClickable(): boolean {
    return this.pageNo < this.totalPageNo;
  }

  get totalPageNo(): number {
    return Math.ceil(this.totalCount / this.ITEMS_PER_PAGE);
  }
}
